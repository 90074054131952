.box{
    width: 100%;
    //background: $gray-xxlight ;
    //border: 1px solid $gray-xxlight-darker;
    min-height: 360px;
    padding:10px;
    position: relative;

    hr{
        width: 100%;
        //border-top: 1px solid $gray-xxlight-darker;
        margin: 10px 0;
    }
    >.loader.js-loader{
        width: 100% !important;
        height: 100%  !important;
        left: 0;
        top: 0;
    }

    &----bg_color,&----green{
        width: 100%;
        background-color: #d3efc1;
        border-color: #73be44;
        min-height: 360px;

        input[type=text],input[type=password],input[type=email],select,textarea, .selectbox select{
            border-color: #73be44;
        }
        hr{
            border-top-color: #73be44;
        }
        .c--box-title----underlined{
            border-bottom-color: #73be44;
        }
    }

    img{
        max-width: 100%;
    }
    &--title{
        text-align: center
    }

    &--ultimate{
        position: relative;
        z-index: 1;
        margin:5% 0;
        .box--title{
            margin-top: -6.5%;
        }
        .box-container{
            margin-top: -50px;
            .container{
                margin-bottom: 10%;
            }
        }
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #cccccc;
            z-index: -1;
            transform: skewY(-13deg);
        }
    }
    /*&----light-gray{
        background-color: $gray-xlight;
        color: $gray-xdark; 
    }
    &-title{
        padding-top: 10px;
        font-size: 1.2em;
        margin-bottom: 15px;

        &----underlined{
            padding-bottom: 10px;
            border-bottom: 1px solid $gray-xxlight-darker;
        }
    }
    &----p0{
        padding: 0;
    }*/
}

@mixin flex() {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-content: stretch;
    align-items: stretch;
    justify-content: flex-start;
    >*{
        flex-grow: 0;
        flex-shrink: 1;
        order: 0;
        flex-basis: auto;
        align-self: auto;
        float: none;
    }
  }


.c--flex{
    @include flex();
    .c--box{
        height: 100%;
    }

    &----column{
        flex-direction: column;
    }

    &----center{
        align-items:center;
    }

    &----bottom{
        align-items:flex-end;
    }

    &----no_wrap{
        flex-wrap: nowrap;
    }
    &----j-end{
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    &----j-center{
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    &-item{
        &----grow{
            flex-grow: 1;
        }
        &----center{
            align-self:center;
        }
        &----bottom{
            align-self:flex-end;
        }
        &----stretch{
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
        }
        &----end{
            align-self: flex-end;
        }
        &----fix{
            flex: none;
        }

        @media (max-width: $small-width - 1px) {
            &-xs{
                &----grow{
                    flex-grow: 1;
                }
                &----center{
                    align-self:center;
                }
                &----bottom{
                    align-self:flex-end;
                }
                &----stretch{
                    -webkit-align-self: stretch;
                    -ms-flex-item-align: stretch;
                    align-self: stretch;
                }
                &----end{
                    align-self: flex-end;
                }
                &----fix{
                    flex: none;
                }
            }
        }
    }
}

.tooltip{
    &-box{
        padding: 5px;
        border:1px solid #ddd;
        background: white;
        display: inline-block;
        min-width: 100px;
        max-width: calc(100% - 10px);
    }
    &-arrow{
        display: block;
        position: absolute;
        top:100%;
        left: 50%;
        &:before,&:after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 7.5px 0 7.5px;
            position: absolute;
            left: -7.5px;
        }
        &:before{
            top:1px;
            border-color: #ddd transparent transparent transparent;
            
        }
        &:after{
            top:-1px;
            border-color: white transparent transparent transparent;
        }
    }
}
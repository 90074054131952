.partial {
  &.contact{
    position: relative;
    text-align: center;
    overflow: hidden;
    @media screen and (min-width: $medium-width) { 
      text-align: left;
    }
    @media screen and (max-width: $small-width) {
      display: none;
    }
    .form {
      background-color: white;
      padding-top: 20px;
      @media screen and (min-width: $medium-width) { 
        display: none;
      }
      button {
        margin-top: 20px;
        font-size: 23px;
        margin-bottom: 30px;
        @media screen and (min-width: $medium-width) { 
          margin: 0;
        }
      }
      &-error {
        color: red;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        text-align: center;
        font-family: $font-family--secondary;
      }
      input, textarea {
        background-color: #EEE;
        margin: 5px 0;
        font-family: $font-family--secondary;
      }

      
  }
  h2{
    margin-bottom: 20px;
    color: $white;
    font-size: 24px;
    text-transform: uppercase;
  }
  .map{
      height: 300px;
      @media screen and (min-width: $medium-width) { 
          height: 610px;
      }
  }
  .box--form{
    padding: 0;
     @media screen and (min-width: $medium-width) { 
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        width: 100%;
      }
    .info{
      font-size: 14px;
      font-family: $font-family--secondary;
      padding: 10px 20px 10px 30px;
      background-color: rgba(37,37,37,.85);
     
      @media screen and (min-width: $medium-width) { 
        border-right: solid $color-primary 60px; 
        padding-bottom: 170px; 
      }
      i{
        position: absolute;
        right: -62px;
        top: 100px;
        font-size: 30px;
        @extend %transition-base;
        &:hover{
            cursor: pointer;
            color: $color-secondary;
            @extend %transition-base;
        }
      }
      .label{
        margin-top: 15px;
        margin-bottom: 5px;
        color: $color-primary; 
        font-weight: bold;
        font-size: 18px;
        &.underlined{
            border-bottom: 2px solid $color-primary;
        }
      }
    }
  } 
}
&.for-all-app{
  //padding: 40px 60px;
  padding: 0 0 40px 0;
  border-bottom:  30px solid $color-primary;
  hr{
    width: 100%;
    border: 15px solid $color-primary;
    z-index: 1;
    position: relative;
  }
  .container{
    z-index: 1;
    position: relative;
    max-width: 100%;
    padding: 0 80px;
    background: white;
  }
  h2{
    font-size: 40px;
    color: #EB2131;
  }
  @media screen and (max-width: $small-width) {
    display: none;
  }
  ul{
    list-style: none;
    @include flex();
    align-items: center;
    justify-content: space-between;
    img{
      height: 140px;
      opacity: 0.3;
      &.grass_tractor{
        height: 85px;
      }
      &.golf_car{
        height: 95px;
      }
      &.compact_car,&.vus_car,&.pick_uk,&.van,{
        height: 135px;
      }
      &.boat{
        height: 105px;
      }
    }
  }
}
&.external-links {
   background-color: $color-primary;
   color: $white;
    @media screen and (min-width: $small-width) {
      height: 80px;
    }
    li {
        list-style: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 30px;
        padding: 10px;
        border-bottom: 1px solid rgba(255,255,255,.5);
        @media screen and (min-width: $medium-width) {
          border: 0;
        }


        div {
            @media screen and (min-width: $small-width) {
              top: 50%;
              position: relative;
              transform: translateY(-50%);
            }
        }
    }
     &.sticky{
        position: fixed;
        top: 60px;
        height: 40px;
        width: 100%;
        @extend %transition-base;
        z-index: 1;
     }
}

}

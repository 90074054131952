header {
    
    background-color: $color-secondary; 
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    @media screen and (min-width: $small-width) {
        height: 60px;
    }
    @media screen and (min-width: $medium-width) {
        height: inherit;
    }

    @extend %transition-base;
     .header-navigation--primary {
        @extend %transition-base;
        margin-top: 20px;
         @media screen and (min-width: $small-width) {
            margin-top: 0px;
        }
        @media screen and (min-width: $medium-width) {
            margin-top: 40px;
        }
    }
    .header-navigation--utility {
        text-align: center;
        @media screen and (min-width: $medium-width) {
            background-color: $color-primary;
            transform: skew(-35deg);
            padding: 40px 0;
            
            font-weight: bold;
        }
        @extend %transition-base;
        ul {
            @media screen and (min-width: $medium-width) {
                transform: skew(35deg);
            }
            li {
                @media screen and (min-width: $medium-width) {
                    font-size: 22px;
                }
            }
        }
    }
    .logo {
        img.background,.background-red {
            z-index: 0;
            top: 0;
            left: 0;
            position: absolute;
            left: -10px;
            width: 583px;
            height: 120%;
            overflow: hidden;
            filter:drop-shadow(5px 6px 3px rgba(0,0,0,0.3));
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                background: $color-primary;
                position: absolute;
                top: 0;
                left: 0;
                transform: skewX(-30deg) translateX(-35px);
            }
            //background:transparent url(../images/background-red-skewed.png) no-repeat no-repeat 0 0;
            @extend %transition-base;
            @media screen and (min-width: $small-width) {
                top: -6px;
             }
            @media screen and (min-width: $medium-width) {
                top: -6px;
            }
            /*&.sticky {
               top: -92px;
               @extend %transition-base; 
            }*/
        }
        img.logo {
           height: 30px;
            margin: 9px 0px;
            z-index: 4;
            position: relative;
            top: 3px;
            @extend %transition-base;
            @media screen and (min-width: $small-width) {
                height: 42px;
            }
            @media screen and (min-width: $medium-width) {
                //margin-top: 20px;
                top: 8px;
                margin: 10px 0px;
                height: 90px;
            }
            &.sticky{
                height: 40px;
                @extend %transition-base;
               // margin-top: 10px;
               top: 3px;
               margin: 10px 0px;
            }
        }
    }
    nav {
        &.header-navigation--utility {
            background-color: $color-primary;
        }
        &.header-navigation--primary { 
            li:last-of-type {
                @media screen and (min-width: $medium-width) {
                    float: right;
                    padding-right: 40px;
                }
            }
        }
        li {
            padding: 10px;
            border-bottom: 1px solid $gray-base;
            font-size: 25px;
            @media screen and (min-width: $medium-width) {
                margin-right:10px;
                display: inline-block;
                padding: 0;
                border-bottom: 0;
                margin-top: 4px;
            }
            @media screen and (min-width: $medium-width) {
                margin-right:80px;
            }
            &.disable {
                color: $gray-base; 
                :hover {
                    cursor: default;
                    text-decoration: none;

                }
            }
            &:last-of-type {
                margin-right: 0; 
                border: 0;
            }
        } 
        .sub-menu {
             li{
                font-size: 22px;
            }
             @media screen and (min-width: $medium-width) {
                display: none;
             }
        }

       
      
    }
    .shadow {
        box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    }
    .slogan {
        display: none;
        background-image: url('/dist/images/slogan.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 350px;
        height: 220px;
        margin-top: -40px;
        margin-left: -25px;
        position: absolute;
        float: right;
        @extend %transition-base;
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
        p {
            font-weight: bold;
            top: 55%;
            transform: translateY(-50%);
            position: relative;
            text-align: center;
            text-transform: uppercase;
            font-size: 26px;
            line-height: 30px;
        }
        &.slide-left {
            margin-left: -500px;
            @extend %transition-base;
        }
    }
    &.sticky{
        height: 60px!important;
        @extend %transition-base;
        .header-navigation--primary {
            @extend %transition-base;
        }
        @media screen and (min-width: $medium-width) {
            .header-navigation--primary {
                margin-top: 15px;
                li {
                    font-size: 20px;
                }
            }
        }
        .header-navigation--utility {
            @extend %transition-base;
            padding: 16.5px 0;
            ul li {
                font-size: 18px;
                 @extend %transition-base;
            }
        }
    }
}
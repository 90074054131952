.anchor {
    //top: -100px;
    position: relative;
}
.tpl {
    padding-top: 80px!important;
    
    padding: 20px 20px 0;
    text-align: left;
    position: relative;
    &:nth-of-type(3) {
        img {
            width: 120%;
        }    
    }
    &:nth-of-type(3) {
        border: 0;
        .line-bottom {
            display: none;
        }
    }
    &:nth-of-type(5) {
        //background-color: white;
    }
     &:nth-of-type(6) {
        //background-color: none;
    }
     &:nth-of-type(7) {
        background-color: transparent;
    }
    &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), &:nth-of-type(7) {
      @media screen and (min-width: $medium-width) {
            min-height: 780px;
        }
     }
    @media screen and (min-width: $small-width) {
        text-align: left;
       
    }
    @media screen and (min-width: $medium-width) {
        //min-height: 1000px;
    }
    .box--button {
        text-align: center;
         @media screen and (min-width: $medium-width) {
            text-align: left;
         }
    }

    button {
        background-color: $color-primary;
        color: white;
        padding: 15px 20px;
        margin: 10px;
        border-radius: 3px;
        @include flex();
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;

        &:hover {
            cursor: pointer;
        }
        i {
            //margin-right: 10px;
        }
        p {
            flex-grow: 1;
            position: static;
            transform: none;
            padding-left: 10px;
            //line-height: 1px;
        }
    }
    .btn {
        overflow: hidden;
        position: relative;
        p {
            font-size: 23px;
            color: white;
            font-family: $font-family--primary;
        }
        span {
          z-index: 20;
        }
        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: .2;
          position: absolute;
          top: -50px;
          transform: rotate(35deg);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          width: 50px;
        }
        &:hover {
          &:after {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
    }
    .container {
        padding: 0;
    }
    .fa {
        font-size: 20px;
    }
    h2,.h2, h3,.h3 {
        margin-bottom: 10px;
    }
    h2,.h2 {
        text-transform: uppercase;
        font-size: 35px;
        //color: $color-primary;
        text-transform: initial;
        color:black;
        border-left: 30px solid $color-primary;
        padding-left: 15px;
        font-weight: normal;
    }
    h3,.h3 {
        color: $color-primary;
        text-transform: inherit;
        font-size: 50px;
        
        &.subtitle {
            text-align: center;
            color:black;
            font-size: 1.25em;

            @media screen and (min-width: $medium-width) {
                text-align: left;
            }
        }

    }
    hr {
        background-color: $color-primary;
        height: 1px;
    }
    ul.types{
        list-style: none;
        @include flex();
        align-items: center;
        margin-bottom: 40px;
        li{
            &:before{
                display: none;
            }
            margin: 0;
        }
        img{
            height: 77px;
            opacity: 0.3;
            width: auto;
            &.grass_tractor{
                height: 46px;
            }
            &.golf_car{
                height: 52px;
            }
            &.compact_car,&.vus_car,&.pick_uk,&.van,{
                height: 75px;
            }
            &.boat{
                height: 57px;
            }
        }
    }
    .image {
        //height: 100%;
        img { 
            width: 75%;
            margin-bottom: 20px;
            @media screen and (min-width: $medium-width) {
                margin-top: 50px;
            }
        }
    }
    p {
        font-size: 16px;
        color: $gray-base;
        font-family: $font-family--secondary;

    }
    &--left {
        //background-color: white;
        //border-bottom: 25px solid $color-secondary;
        position: relative;
        padding-bottom: 10%;
        @media screen and (min-width:$medium-width){
            padding-top: 40px!important;
            h2{
                text-align: right;
                border-left: 0;
                border-right: 30px solid #EB2131;
                padding-left: 0;
                padding-right: 15px;
            }
            ul.types {
                justify-content: flex-end;
            }
            .col-md-6:first-child{
                .image{
                    padding-top: 40px;
                }
            }
        }
        &::before{
            content: "";
            display: block;
            background: white;
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: skewY(-4deg);
            border-top: 15px solid #d9d9d9;
            border-bottom: 15px solid #d9d9d9;
        }
        .line-bottom {
            height: 45px;
            background-color: $color-primary;
            bottom: -25px;
            position: absolute;
            right: 0;
            display: none;
             @media screen and (min-width: $medium-width) {
                height: 85px;
               // display: block;
            }
            .line-skew {
                transform: skew(-30deg);
                background-color: $color-primary;
                width: 100px;
                height: 100%;
                margin-left: -50px;
            }
        }
       
        &+form+.for-all-app{
            margin-top: -30px;
            margin-top: -20px;
            display: inline-block;
            width: 100%;
        }
    }
    &--right {
        //border-bottom: 25px solid $color-secondary;
        padding-bottom: 3%;
        &:before{
            content: "";
            display: block;
            position: absolute;
            top:-35px;
            left: 0;
            right: 0;
            bottom: -35px;
            transform: skewY(-4deg);
            border-top: 15px solid #eaeaea;
            border-bottom: 15px solid #eaeaea;
            z-index: 1;
            pointer-events: none;
        }

        .line-bottom {
            height: 45px;
            background-color: $color-primary;
            bottom: -25px;
            margin-left: -25px;
            display: none;
            @media screen and (min-width: $medium-width) {
                position: absolute;
                left: 0;
                margin-left: 0;
                height: 85px;
                //display: block;
            }
            .line-skew {
                 transform: skew(30deg);
                background-color: $color-primary;
                width: 100px;
                height: 100%;
                margin-right: -50px;
                float: right;
            }
        }

        &+form+.for-all-app {
            //margin-top: 20px;
            position: relative;

            &::before{
                content: "";
                display: block;
                background: white;
                position: absolute;
                top:0;
                left: 0;
                right: 0;
                bottom: 25%;
                transform: skewY(-4deg);
                border-top: 15px solid #d9d9d9;
            }
            &::after{
                content: "";
                display: block;
                background: white;
                position: absolute;
                top:50%;
                left: 0;
                right: 0;
                bottom: 0px;
            }
        }
    }
    &--ultimate{
        h2{
            text-align: left;
            border-right: 0;
            border-left: 30px solid #EB2131;
            padding-right: 0;
            padding-left: 15px;
        }
        ul.types{
            justify-content: flex-start;
        }

        @media screen and (min-width:$medium-width){
            .col-md-6:first-child{
                .image{
                    padding-top: 0px;
                }
            }
        }

        &:before {
            border-top:0;
            top:25%;
        }
        &::after{
            content: "";
            display: block;
            background: white;
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 50%;
        }
        .container{
            position: relative;
            z-index: 1;
        }
    }
     &--store {
        padding-top: 0!important;
         @media screen and (min-width: $medium-width) {
            min-height: 600px;
        }
        a {
            &.link--back{
                 padding-top: 0;
                 .fa {
                    color: $color-primary;
                    padding-right: 5px;
                 }
            }
            display: inline-table;
        }
        button {
            &.button--direction, &.button--share {
                 width: 100%;
                 font-weight: bold;
                 margin: 10px 0 0!important;
                .fa {
                     float: right;
                }
            }
        }
       
        .block-info-garage {
            background-color: $gray-light;
            padding: 35px 25px;
        }
        .list-info-block ul {
            list-style: none;
            font-size: 14px;
            li {

                .fa-absolute {
                    color: $color-primary;
                    left: -10px;
                    position: relative;
                    top: 2px;
                }
                 &:before {
                    content: '';
                }
            }
            .open-hours {
                display: inline-table;
            }
        }
         .map-store {
            height: 300px !important; 
            position: relative; 
            overflow: hidden;
        }
     }
     .list-info-block ul > li {
        margin-bottom: 20px;
        margin-left: 10px;
     }
     .list-info-block .days > li,  .list-info-block .hours > li{
        margin: 0;
     }
       .list-info-block .days{
        float: left;
     }
    .list-info-block .hours{
        display: inline-table;
        text-align: right;
        margin-left: 20px;
     }
    &--store-locator {
        margin-bottom: -20px;
        height: 600px;
        @media screen and (min-width: $medium-width) {
            height: inherit; 
            min-height: 1000px;
        }
        div[class^=" _ibani_"] {
            box-shadow: 0 5px 5px rgba(0,0,0,.35);
        }
        .bg-tools {
            background-color: $color-primary; 
            width: 440px;
            height: 150px;
            top: 150px; 
            margin: auto;
            z-index: 1;
            left: 0;
            right: 0;
            position: absolute;
            border-radius: 3px;
        }  
        .border--right-white {
            @media screen and (min-width: $medium-width) {
                border-right: 1px solid rgba(255,255,255,0.25);
            }
        }
        .fa-close {
            cursor: pointer;
            right: 10px;
            top: 10px;
            position: absolute;
            z-index: 1;
        }
        form {
            div {
                display: inline-block;
            }
        }
        h3 {
            text-transform: uppercase;
            border-bottom: 1px solid $gray-light;
        }
        .info-bubble-close {
            right: 10px!important;
            top: 10px!important;
        }
        .gm-style-iw {
            top: 15px !important;
            padding: 10px;
            font-size: 18px;
        }
        .infobubble-text {
            font-size: 18px;
            text-transform: uppercase;
        }
        .input--search-map {
            margin-top: 60px;
            background: white;
            padding: 10px;
            font-size: 16px;
            width: 100%;

        }
        .map {
            height: 100%;
            position: absolute;
            width: 100%;
            top: 220px;
            @media screen and (min-width: $small-width) {
                top: 180px;
            }
        }

        .map--tools {
            position: relative;
            background-color: $color-primary;
            z-index: 1;
            height: 220px;
            top: 5px;
            padding-top: 15px;
             @media screen and (min-width: $small-width) {
                height: 140px;
             }
            @media screen and (min-width: $medium-width) {
                top: 30px;
                height: inherit;
            }
            padding-bottom: 20px;
            .text, .label, .range {
                position: absolute;
                color: white;
                top: 10px;
                font-family: $font-family--secondary; 
                font-size: 14px;
            }
           
            .range {
                top: 40px;
                right: 20px;
            }
        }
  
        .modal--store-info { 
            position: fixed;
            z-index: 100;
            display: none;
            background-color: white;
            padding: 20px;
            top: 70px;
            box-shadow: 0 10px 10px rgba(0,0,0,.35);
             @media screen and (min-width: $medium-width) {
                 width: 400px;
                 left: 40px;
                 top: 50%;
                 transform: translateY(-50%);
             }
            .btn {
                width: 100%;
                font-weight: bold;
                margin: 0;
                margin-bottom: 10px;
            }
            p {
                display: inline-table;
                font-size: 14px;
            }
            ul {
                list-style: none;
                font-size: 14px;
                li {
                    margin-left: 20px;
                    .fa-absolute {
                        color: $color-primary;
                        left: -10px;
                        position: relative;
                        top: 2px; 
                    }
                     &:before {
                        content: '';
                    }
                }
            }

            a {
                color: $color-primary;
            }
            h2,.h2 {
                font-size: 40px;
                color: $color-primary; 
            }
            h3,.h3 {
                color: $color-secondary;
                font-size: 1.125em;
            }
           p, ul {
                color: $color-secondary;
                margin-bottom: 10px;
                font-family: $font-family--secondary;
           }
        }
        p {
            color: $color-secondary;
        }
       
        .slider {
            text-align: center;
            top: 70px;
        }
    }
    ul {
        text-align: left;  
        font-family: $font-family--secondary;
        li {
            margin-left: 10px;
            list-style: none;
            @media screen and (min-width: $medium-width) {
                margin-left: 30px;
            }
            &:before {
                content: '■';
                color: $color-primary;
                float: left;
                width: 1em;
                margin: 0 -1.2em;
                font-size: 20px;
                line-height: 14px;
            }
        }
    }
}

